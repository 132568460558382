.mainDiv {

    display: 'flex';
    background-color: #20bf55;
    background-image: linear-gradient(315deg, #20bf55 0%, #01baef 74%);
    height: -webkit-fill-available;
}

.appLink {
    text-decoration: none;
    color: #101010;
}

.rootDiv {
    flex: 1 1 0%;
    -webkit-box-align: stretch;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    align-items: stretch;
    border: 0px solid black;
    box-sizing: border-box;
    display: flex;
    flex-basis: auto;
    flex-direction: column;
    flex-shrink: 0;
    margin: 0px;
    min-height: 0px;
    min-width: 0px;
    padding: 0px;
    position: relative;
    z-index: 0;
}

.cardDiv {
    width: 90%;
    box-shadow: rgb(78 78 78 / 25%) 0px 4px 12px;
    max-width: 700px;
    align-self: center;
    margin-bottom: 30px;
    margin-top: 30px !important;
    overflow: hidden;
    border-radius: 20px;
    -webkit-box-align: stretch;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    align-items: stretch;
    border: 0px solid black;
    box-sizing: border-box;
    display: flex;
    flex-basis: auto;
    flex-direction: column;
    flex-shrink: 0;
    margin: 0px;
    min-height: 0px;
    min-width: 0px;
    padding: 0px;
    position: relative;
    z-index: 0;
}

.mainCardDiv {
    -webkit-box-align: stretch;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    align-items: stretch;
    border: 0px solid black;
    box-sizing: border-box;
    display: flex;
    flex-basis: auto;
    flex-direction: column;
    flex-shrink: 0;
    margin: 0px;
    min-height: 0px;
    min-width: 0px;
    padding: 0px;
    position: relative;
    z-index: 0;
    background-color: rgb(255, 255, 255);
    padding-bottom: 16px;
    padding-top: 16px;
    padding-left: 20px;
    padding-right: 20px;
}


.innerContent {
    max-width: 400px;
    -webkit-box-align: stretch;
    align-items: stretch;
    align-self: center;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    flex: 1 1 0%;
    -webkit-box-align: stretch;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    align-items: stretch;
    border: 0px solid black;
    box-sizing: border-box;
    display: flex;
    flex-basis: auto;
    flex-direction: column;
    flex-shrink: 0;
    margin: 0px;
    min-height: 0px;
    min-width: 0px;
    padding: 0px;
    position: relative;
    z-index: 0;

}

.cardText {
    font-weight: 600;
    font-size: 25px;
    text-align: center;
    margin-bottom: 20px;
}

.eveName {
    margin-bottom: 20px;
    font-weight: 600;
    text-align: center;
    font-size: 19px;
    flex-wrap: wrap;
}

.divAddrs {


    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    flex-direction: column;
    margin-bottom: 12px;
    margin-top: 12px;
    -webkit-box-align: stretch;
    align-items: stretch;
    border: 0px solid black;
    box-sizing: border-box;
    display: flex;
    flex-basis: auto;

    flex-shrink: 0;
    margin: 0px;
    min-height: 0px;
    min-width: 0px;
    padding: 0px;
    position: relative;
    z-index: 0;
}

.addressContnent {
    font-family: Share;
    line-height: 24.7px;
    font-size: 19px;
    box-sizing: border-box;
    color: rgb(0, 0, 0);
    display: inline;
    font: 14px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    margin: 0px;
    padding: 0px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
}

.mapouter {
    position: relative;
    text-align: right;
    height: 200px;
    width: 380px;
}

.gmap_canvas {
    overflow: hidden;
    background: none !important;
    height: 200px;
    width: 380px;
}

.contentText {
    border: 0px solid black;
    box-sizing: border-box;
    color: rgb(0, 0, 0);
    display: inline;
    font: 14px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    margin: 0px;
    padding: 0px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: center;
    font-size: 16px;
}

.divCTAbtns {
    -webkit-box-align: stretch;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: stretch;
    border: 0px solid black;
    box-sizing: border-box;
    display: flex;
    flex-basis: auto;
    flex-shrink: 0;
    margin: 0px;
    min-height: 0px;
    min-width: 0px;
    padding: 0px;
    position: relative;
    z-index: 0;
    margin-bottom: 20px;
}

.btn {
    background-color: #01baef;
    /* background-image: linear-gradient(315deg, #20bf55 0%, #01baef 74%); */
    padding-top: 11px;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 12px;
    border-radius: 12px;
    max-width: 100px;
    -webkit-box-flex: 1;
    flex-grow: 1;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
    box-shadow: rgb(78 78 78 / 20%) 0px 2px 4px;
}

@media only screen and (min-width: 360px) and (max-width: 480px) {
    .mapouter {
        position: relative;
        text-align: right;
        height: 200px;
        width: 280px;
    }
    .gmap_canvas {
        overflow: hidden;
        background: none !important;
        height: 200px;
        width: 280px;
    }

    .mainDiv {

        display: 'flex';
        background-color: #20bf55;
        background-image: linear-gradient(315deg, #20bf55 0%, #01baef 74%);
        height: 100vh;
    }

}