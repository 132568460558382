.container {
    background-color: #77A8AF!important;
    max-width: 1140px;
    margin: 0 auto;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    padding: 41px;
}

.logoText {
    text-align: center;
    margin: 25px auto;
    font-weight: 500;
    font-size: 2em;
    font-family: Roboto,sans-serif;
    color: #fff;

}

.logoDim {
    margin-bottom: 25px;
    width: 250px;
}

.card {
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
    background: #fff;
    color: rgba(0,0,0,.87);
    transition: box-shadow 280ms cubic-bezier(.4,0,.2,1);
    display: block;
    position: relative;
    padding: 16px;
    border-radius: 4px;
    margin-bottom: 20px;
}

.cardContent {
    text-align: justify;
    font-size: large;
}

.textContent {
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 14px;
}


@media (min-width: 992px) {
    .container {
        background-color: #77A8AF!important;
        max-width: 720px;
        margin: 0 auto;
    }
}