.backgroundPicDiv {
    -webkit-box-align: stretch;
    -webkit-box-direction: stretch;
    -webkit-box-orient: vertical;
    align-items: stretch;
    border: 0px solid black;
    box-sizing: border-box;
    display: flex;
    flex-basis: auto;
    flex-direction: column;
    flex-shrink: 0;
    margin: 0px;
    min-height: 0px;
    min-width: 0px;
    padding: 0px;
    position: relative;
    z-index: 0;
}



.divDateOpsMain {
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    flex-direction: column;
    align-items: stretch;
    border: 0px solid black;
    box-sizing: border-box;
    display: flex;
    flex-basis: auto;
    flex-direction: column;
    flex-shrink: 0;
    margin: 0px;
    min-height: 0px;
    min-width: 0px;
    padding: 0px;
    position: relative;
    z-index: 0;
}

.dateItem {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    flex-direction: column;
    -webkit-box-align: stretch;
    margin-bottom: 20px;
    align-items: stretch;
    border: 0px solid black;
    box-sizing: border-box;
    display: flex;
    flex-basis: auto;
    flex-direction: column;
    flex-shrink: 0;
    margin: 0px;
    min-height: 0px;
    min-width: 0px;
    padding: 0px;
    position: relative;
    z-index: 0;
}


.dateContent {
    display: flex;
    flex-direction: row;
    /* justify-content: space-around; */
    padding: 5px;
}

.voteDetails {
    text-align: start;
    font-size: 14px;
}

.btnSel {
    background-color: #cfd3d4;
    /* background-image: linear-gradient(315deg, #20bf55 0%, #01baef 74%); */
    padding-top: 11px;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 12px;
    border-radius: 12px;
    max-width: 100px;
    -webkit-box-flex: 1;
    flex-grow: 1;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
    box-shadow: rgb(78 78 78 / 20%) 0px 2px 4px;
}

.addressCn {
    border: 0px solid black;
    box-sizing: border-box;
    color: rgb(0, 0, 0);
    display: inline;
    font-size: 14px;
    margin: 0px;
    padding: 0px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
}

.btnCon {
    align-self: center;
    background-color: #01baef;
    /* background-image: linear-gradient(315deg, #20bf55 0%, #01baef 74%); */
    padding-top: 11px;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 12px;
    border-radius: 12px;
    max-width: 100px;
    flex-grow: 1;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
    box-shadow: rgb(78 78 78 / 20%) 0px 2px 4px;
}


.dataContainer {
    margin-left: 35px;
}

.footerCard {

    background-color: #000000;
    padding-top: 40px;
    color: #ffffff;
    margin-top: 20px;
} 

.footerContainer {
    width: 100%;
    max-width: 1140px;
    padding: 0 15px;
    margin: 0 auto;
}

.footer-row {
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
}
.footer-col {
    width: 300px;
    padding: 0 30px 40px;
}

.googlePlayLogo {
        margin-top: 30px;
}


.textItemStyle {
    font-size: 17px;
}

.textLabelStyle {
    font-size: 18px;
}


.mobileOne {
    display: none;
}


.popupCancel {
    float: right;
    margin-top: -30px;
    font-weight: bolder;
}

@media only screen and (min-width: 360px) and (max-width: 480px) {

    .addressCn {
        text-align: initial;
        font-size: 12px;
        display: block;

    }


    .mobileOne {
        display: flex;
        justify-content: center;
    }

    .mobcard {
        position: sticky;
        top: calc(100vh - 2rem);
        background: #fff;
        padding: 3rem;
        color: black;
        text-align: center;
        font-weight: bold;
        box-shadow: 0 0 30px rgb(0 0 0 / 8%);
        z-index: 999;
        width: 92%;
        transform: translateY(-2em);
        border-radius: 1em;
    
    }




    .backgroundPicDiv {
        display: none;
      
    }

    .voteDetails {
        text-align: start;
        font-size: 12px;
    }


    .textItemStyle {
        font-size: 15px;
    }

    .textLabelStyle {
        font-size: 16px;
    }

    .appPopupbtn {
        display: inline-flex;
        padding: 12px;
    }
    .appPopupText {
        text-align: initial;
    font-size: 18px;
    padding: 10px;
    font-weight: 300;

    }

}